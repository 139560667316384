import { Spinner } from '@chakra-ui/react'
import { datadogRum } from '@datadog/browser-rum'
import { Component } from 'react'
import {
  EncodingCounters,
  Encodings,
  EncodingValidationRequest,
  EncodingValidationResponse,
  ItemConfigurations,
  Places,
  Template,
  Templates,
  TmrPlace,
  TmrTag,
} from 'stylewhere/api'
import {
  Box,
  EncodingForm,
  EncodingIdentifers,
  EncodingProduct,
  EncodingReading,
  EncodingRightHeader,
  FormSchemaForm,
  FullLoadingLayer,
  InputModal,
  Page,
  Text,
} from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import {
  AppStore,
  FormSchemaData,
  OperationReadingProps,
  RemoteOperation,
  RfidReader,
  Router,
  Sounds,
} from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  askUserConfirmation,
  BLOCKED_ERRORS,
  enabledWamGroupedProduct,
  getAskUserInputEveryTime,
  getCanForceAssocation,
  getEncodingAssociationStatus,
  getEncodingProductFields,
  getInitialType,
  getTagType,
  isExpectOnlyMandatoryIdentifiers,
  isModalError,
  isRfidAndSerial,
  isSkipValidation,
  showToast,
  showToastError,
  __isDev,
} from 'stylewhere/shared/utils'
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'

interface State {
  encodingValidation?: EncodingValidationRequest
  encodingValidationResponse?: EncodingValidationResponse
  encodingCreateResponse?: EncodingValidationResponse
  formSchemaData?: FormSchemaData
  options: {
    value: string
    label: string
    active: boolean
  }[]
  counters?: EncodingCounters
  tagsRead: TmrTag[]
  processing: boolean | 'nfcDecryption'
  associationStatus: 'TO_BE_READ' | 'CONFIRMED' | 'ERROR' | 'PROCESSING' | 'IGNORED' | 'SKIPPED'
  showPin: boolean
  antennaOn: boolean
  forceAssociation: boolean
  pin: string
  configuration: any[]
  encodingCreateCounter: number
  targetTag: string
  serial: string
  numWriteAttempt: number
  starting: boolean
  resetFormCounter: number
  availablePrinters: DeviceInfo[]
  selectedPrinterUid?: string
  printTemplate?: Template
}

interface DeviceStatus {
  isReadyToPrint?: boolean
  errors?: string
}

type DeviceInfo = DeviceStatus & {
  device: Device
  isDefault: boolean
}

interface Device {
  name: string
  deviceType: string
  connection: string
  uid: string
  provider: string
  manufacturer: string
  version: number
}

export default class BrowserPrintEncode extends Component<OperationReadingProps<State>, State> {
  browserPrint = new ZebraBrowserPrintWrapper()

  lastSerial

  state: State = {
    options: [
      { value: 'associate', label: __(T.misc.associate), active: true },
      { value: 'verify', label: __(T.misc.verify), active: false },
    ],
    formSchemaData: { product: { code: '' }, wam: '' },
    tagsRead: [],
    processing: false,
    associationStatus: 'TO_BE_READ',
    showPin: false,
    antennaOn: false,
    pin: '',
    forceAssociation: false,
    configuration: [],
    encodingCreateCounter: 0,
    targetTag: '',
    serial: '',
    numWriteAttempt: 0,
    starting: false,
    resetFormCounter: 1,
    availablePrinters: [],
  }

  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<EncodingOperationConfig>(this.matchParams.opCode)

  isEncoding = false
  isDecodeNfc = false
  isWrongTag = false
  isModal = false
  timer: NodeJS.Timeout | null = null
  timerReader?: any
  timerTags: NodeJS.Timeout | null = null
  timerNoRead: NodeJS.Timeout | null = null

  ddFullEncodeTime = 0

  componentDidMount = async () => {
    this.isModal = isModalError(this.operation)

    if (!AppStore.zbpEmulation) {
      await this.fetchPrintTemplate()
      await this.getPrinters()
    }

    const initialType = getInitialType(this.operation)
    if (initialType === 'identifier') {
      if (!isSkipValidation(this.operation)) {
        this.setState({ starting: true }, this.startReader)
      } else {
        //get itemConfiguration
        const config = await this.getItemConfiguration()
        if (config) {
          const encodingValidation: EncodingValidationRequest = {
            identifiers: [],
            operationId: this.operation.id,
            placeId: AppStore.defaultWorkstation!.placeId,
          }
          this.setState(
            { configuration: config, forceAssociation: true, encodingValidation: encodingValidation },
            this.startReader
          )
        } else {
          showToastError(__(T.error.no_item_configuration), __(T.error.error), this.isModal)
        }
      }
    }
  }

  getItemConfiguration = async () => {
    let configuration = undefined
    let itemConfig = ''
    let itemConfigDefault = ''
    const keys = Object.keys(this.operation.options)
    let key
    for (let k = 0; k < keys.length; k++) {
      if (keys[k].indexOf('itemTypeConfig_') !== -1) {
        key = keys[k].replace('itemTypeConfig_', '')
        if (key === 'default') {
          itemConfigDefault = this.operation.options[keys[k]] || ''
        } else if (itemConfig === '') {
          itemConfig = this.operation.options[keys[k]] || ''
        }
      }
    }
    if (itemConfig !== '' || itemConfigDefault !== '') {
      const res: any = await ItemConfigurations.search({
        equalCodes: itemConfig !== '' ? itemConfig : itemConfigDefault,
      })
      if (res && res.content && res.content.length > 0 && res.content[0].details && res.content[0].details.length > 0) {
        configuration = res.content[0].details
      }
    }
    return configuration
  }

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.start(undefined, () => {}, undefined)
    }
  }

  stopAntenna = async () => {
    // if (RfidReader.isReading()) {
    this.setState({ starting: true })
    await RfidReader.stop()
    // }
  }

  onSubmitForm = async (formSchemaData: FormSchemaData) => {
    const { resetFormCounter, selectedPrinterUid, printTemplate } = this.state
    if (!AppStore.zbpEmulation && !selectedPrinterUid) {
      showToastError(__(T.error.error), __(T.error.no_printer_selected), this.isModal)
      return
    }
    if (!AppStore.zbpEmulation && !printTemplate) {
      showToastError(__(T.error.error), __(T.error.no_print_template), this.isModal)
      return
    }

    if (!formSchemaData.product.code) {
      showToastError(__(T.error.error), __(T.identifier_error.MISSING_PRODUCT_INFO), this.isModal)
      return
    }

    try {
      const res = await EncodingExtensions.getItemConfiguration(this.operation, formSchemaData)
      this.setState(
        {
          encodingValidation: res.encodingValidation,
          encodingValidationResponse: res.encodingValidationResponse,
          counters: res.counters,
          formSchemaData,
        },
        async () => {
          try {
            const computeTagsResponse = await Encodings.computeTags(
              this.operation.id,
              [],
              AppStore.defaultWorkstation!.id,
              this.state.encodingValidationResponse!.item.product.id
            )
            const newEpcId = computeTagsResponse.find((r) => r.identifierType === 'UHF_TAG')
            const newSerialId = computeTagsResponse.find((r) => r.identifierType === 'SIMPLE_ITEM_IDENTIFIER')
            if (!newEpcId || !newSerialId) return await showToastError(__(T.error.error), __(T.error.error))

            if (__isDev) console.log('new Epc', newEpcId.desiredCode)

            const newEncodingValidation = { ...this.state.encodingValidation! }
            newEncodingValidation.identifiers.forEach((element) => {
              if (element.identifierType === 'SIMPLE_ITEM_IDENTIFIER') {
                element.code = newSerialId.desiredCode
                element._status = 'SKIPPED'
              }
            })

            this.setState(
              {
                associationStatus: 'TO_BE_READ',
                targetTag: newEpcId.desiredCode,
                serial: newSerialId.desiredCode,
                encodingValidation: newEncodingValidation,
              },
              async () => {
                await this.sendZbpPrint()
                await this.startReader()
                this.ddFullEncodeTime = new Date().getTime()
              }
            )
          } catch (e) {
            showToastError(e, __(T.error.error))
          }
        }
      )
    } catch (error) {
      this.setState({ resetFormCounter: resetFormCounter + 1 })
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  startReader = async () => {
    const useRfidReaderStop = false
    const initialType = getInitialType(this.operation)
    try {
      this.isWrongTag = false
      await RfidReader.initialize()
      if (initialType === 'identifier' || useRfidReaderStop) {
        RfidReader.setAutomaticStop(false)
      } else {
        RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
        RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
      }
      RfidReader.setIgnoreReadingTagsWithPrefixes(
        this.operation.options.ignoreReadingTagsWithPrefixes && this.operation.options.ignoreReadingTagsWithPrefixes
          ? this.operation.options.ignoreReadingTagsWithPrefixes
          : ''
      )
      RfidReader.setOnTagReadCallback(initialType === 'identifier' ? this.onTagReadIdentifier : this.onTagRead)
      RfidReader.onStartCallback = this.onStartCallback
      RfidReader.onStopCallback = this.onStopCallback
      //perchè c'è lo stop? se c'è la stop non possiamo usare stop autamtico antenne nella onStopCallback
      if (useRfidReaderStop) await RfidReader.stop()
      await RfidReader.stopWsCustomCommands()
      await RfidReader.start(undefined, () => {}, undefined)
      if (useRfidReaderStop) this.attachAutomaticReaderStop()
    } catch (error) {
      showToastError((error as any).message ?? __(T.error.rfid_reader_initialization), __(T.error.error), this.isModal)
    }
  }

  onStartCallback = () => {
    this.setState({ antennaOn: true, starting: false })
  }

  onStopCallback = () => {
    const initialType = getInitialType(this.operation)
    const tmpEncoding = this.state.encodingValidation
    if (initialType !== 'identifier') {
      RfidReader.setAutomaticStop(false)
      RfidReader.stopTimer()
    }
    if (tmpEncoding && tmpEncoding.identifiers) {
      // && tmpEncoding.identifiers.findIndex((idt) => idt._read) === -1
      tmpEncoding.identifiers
        .filter((id) => id.identifierType !== 'SIMPLE_ITEM_IDENTIFIER')
        .filter((id) => !id._read)
        .filter((id) => id._status !== 'ERROR' && id._status !== 'NFC_COLLISION')
        .forEach((idf) => {
          idf._status = 'ERROR'
          idf._error = __(T.imbustatrice.dynamic_tag_missing, { role: idf.role })
        })
      this.setState({ antennaOn: false, starting: false, associationStatus: 'ERROR', encodingValidation: tmpEncoding })
    } else {
      this.setState({ antennaOn: false, starting: false })
    }
  }

  clearTimerReader = () => {
    if (this.timerReader) {
      clearTimeout(this.timerReader)
      this.timerReader = null
    }
  }

  attachAutomaticReaderStop = () => {
    if (this.operation.autostopAntennaTimeout > 0) {
      this.clearTimerReader()
      this.timerReader = setTimeout(async () => {
        this.setState({ associationStatus: 'ERROR' })
        await this.stopAntenna()
        if (this.checkIfForceCreate('yes')) {
          this.isEncoding = true
          this.setState(
            { forceAssociation: true, pin: '', encodingCreateCounter: this.state.encodingCreateCounter + 1 },
            this.create
          )
        } else if (this.checkIfForceCreate('withUserConfirmation')) {
          const { encodingValidationResponse } = this.state
          const errors =
            encodingValidationResponse && encodingValidationResponse.errors ? encodingValidationResponse.errors : []
          // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
          if (
            errors.length == 1 &&
            errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
            isExpectOnlyMandatoryIdentifiers(this.operation)
          ) {
            this.call_force_encoding(false)
          }
          // else {
          //   const ok = await askUserConfirmation(
          //     __(T.misc.attention),
          //     this.getWithUserConfirmationMessage(errors, encodingValidationResponse)
          //   )
          //   if (ok) {
          //     this.call_force_encoding(false)
          //   } else {
          //     this.clear()
          //     this.isEncoding = false
          //   }
          // }
          // } else {
          // this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, () => this.create(true))
        }
      }, this.operation.autostopAntennaTimeout)
    }
  }

  componentWillUnmount = async () => {
    this.setState({ processing: false })
    this.clearTimerNoReads()
    this.clearTimerTags()
    this.clearTimerReader()
    this.clearTimer()
    await this.stopAntenna()
  }

  matchConfigurationTag = (identifiers) => {
    const { configuration } = this.state
    console.log(configuration)
    console.log(identifiers)
    return false
  }

  verifySerial = async (tag: TmrTag) => {
    this.lastSerial = tag
    this.setState({ processing: true }, this.executeVerify)
  }

  executeVerify = async () => {
    try {
      const tmpEncodingValidation: EncodingValidationRequest = {
        identifiers: [],
        operationId: this.operation.id,
        placeId: AppStore.defaultWorkstation!.placeId,
      }
      await EncodingExtensions.onTagRead(tmpEncodingValidation, this.lastSerial, this.operation)
      const encode = await EncodingExtensions.verify(tmpEncodingValidation)
      if (
        encode &&
        encode.encodingValidationResponse &&
        encode.encodingValidationResponse.item &&
        encode.encodingValidationResponse.item.identifiers
      ) {
        const idt = encode.encodingValidationResponse.item.identifiers.find((e) => e.type === 'UHF_TAG')
        if (idt) {
          this.lastSerial = null
          const tmpTag = { type: 'UHF_TAG', epc: idt.code }
          const tmpTagsRead = this.state.tagsRead
          tmpTagsRead.push(tmpTag as TmrTag)
          tmpEncodingValidation.identifiers = []
          this.setState(
            { encodingValidation: tmpEncodingValidation, tagsRead: tmpTagsRead },
            this.validateIdentifiersTag
          )
        } else {
          this.setState({ processing: false })
          this.removeTagReadFromReader([this.lastSerial])
          this.lastSerial = null
          showToastError('Unknown error during encoding validation', __(T.error.error), this.isModal)
        }
      } else {
        this.setState({ processing: false })
        this.removeTagReadFromReader([this.lastSerial])
        this.lastSerial = null
        showToastError('Unknown error during encoding validation', __(T.error.error), this.isModal)
      }
    } catch (error) {
      this.setState({ processing: false })
      showToastError(
        (error as any).message ?? 'Unknown error during encoding validation',
        __(T.error.error),
        this.isModal
      )
    }
  }

  onTagReadIdentifier = async (tag: TmrTag) => {
    const { encodingValidation, encodingCreateCounter } = this.state
    const tmpTagsRead = this.state.tagsRead
    const identifierType = getTagType(tag)
    if (isSkipValidation(this.operation)) {
      tmpTagsRead.push(tag)
      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.operation)
      const matches = this.matchConfigurationTag(encodingValidation?.identifiers)
      if (matches) {
        //await this.stopAntenna()
        this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
      } else {
        this.setState({ tagsRead: tmpTagsRead, encodingValidation: encodingValidation }, this.startTimerNoReads)
      }
    } else {
      if (!encodingValidation && identifierType === 'UHF_TAG') {
        tmpTagsRead.push(tag)
        this.setState({ tagsRead: tmpTagsRead }, this.startTimerTags)
      } else {
        tmpTagsRead.push(tag)
        if (encodingValidation) {
          await EncodingExtensions.onTagRead(encodingValidation, tag, this.operation)
          this.setState({ tagsRead: tmpTagsRead, encodingValidation: encodingValidation }, this.startTimerTags)
        } else {
          this.setState({ tagsRead: tmpTagsRead })
        }
      }
    }
  }

  validateIdentifiersTag = async () => {
    const { tagsRead } = this.state
    //await this.stopAntenna()
    const tmpEncodingValidation: EncodingValidationRequest = {
      identifiers: [],
      operationId: this.operation.id,
      placeId: AppStore.defaultWorkstation!.placeId,
    }
    for (let t = 0; t < tagsRead.length; t++) {
      await EncodingExtensions.onTagRead(tmpEncodingValidation, tagsRead[t], this.operation)
    }
    try {
      const res = await EncodingExtensions.validate(tmpEncodingValidation, false)
      if (
        res.encodingValidationResponse.item &&
        res.encodingValidationResponse.item.product &&
        res.encodingValidationResponse.item.product !== null
      ) {
        await this.addIdentifierRole(res)
        await this.addIdentifierConfiguration(res)
        if (res.encodingValidationResponse.success) {
          this.isEncoding = true
        }
        this.setState(
          {
            encodingValidationResponse: res.encodingValidationResponse,
            encodingValidation: res.encodingValidation,
            processing: res.encodingValidationResponse.success,
          },
          res.encodingValidationResponse.success ? this.onTagReadValidateResponse : this.startTimerNoReads
        )
      } else {
        this.setState({ processing: false })
        await this.stopAntenna()
        this.removeTagReadFromReader(tagsRead)
        let modalText = __(T.error.tags_errors)
        if (res && res.encodingValidationResponse && res.encodingValidationResponse.errors) {
          let errorCheck = res.encodingValidationResponse.errors.find((e) => e.errorCode === 'MISSING_PRODUCT_INFO')
          if (errorCheck) {
            modalText = __(T.error.only_virgin_tag)
          } else {
            errorCheck = res.encodingValidationResponse.errors.find(
              (e) => e.errorCode === 'Tag Mismatch' || e.errorCode === 'TAG_MISMATCH'
            )
            if (errorCheck) {
              modalText = __(T.error.tags_mismatch_error)
            }
          }
        }
        showToastError(modalText, __(T.error.error), true, this.closeVirginTagModal, 'OK')
        this.feedbackSoundKo()
      }
    } catch (error) {
      this.setState({ processing: false })
      this.removeTagReadFromReader(tagsRead)
      showToastError(
        (error as any).message ?? 'Unknown error during encoding validation',
        __(T.error.error),
        this.isModal
      )
    }
  }

  closeVirginTagModal = async () => {
    this.clearTimer()
    this.clearTimerTags()
    this.clearTimerNoReads()
    await this.startAntenna()
    this.setState({
      encodingValidation: undefined,
      encodingValidationResponse: undefined,
      counters: undefined,
      tagsRead: [],
      associationStatus: 'TO_BE_READ',
      pin: '',
      forceAssociation: false,
      encodingCreateCounter: 0,
    })
  }

  removeTagReadFromReader = (tags: TmrTag[]) => {
    const toRemove: string[] = []
    for (let t = 0; t < tags.length; t++) {
      toRemove.push(tags[t].epc)
    }
    RfidReader.removeTags(toRemove)
  }

  clearTimerNoReads = () => {
    if (this.timerNoRead) {
      clearTimeout(this.timerNoRead)
      this.timerNoRead = null
    }
  }

  startTimerNoReads = async () => {
    const { encodingValidation } = this.state
    if (encodingValidation) {
      if (this.checkIfForceCreate('yes')) {
        this.isEncoding = true
        this.setState(
          { forceAssociation: true, pin: '', encodingCreateCounter: this.state.encodingCreateCounter + 1 },
          this.create
        )
      } else if (this.checkIfForceCreate('withUserConfirmation')) {
        const { encodingValidationResponse } = this.state
        const errors =
          encodingValidationResponse && encodingValidationResponse.errors ? encodingValidationResponse.errors : []
        // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
        if (
          errors.length == 1 &&
          errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
          isExpectOnlyMandatoryIdentifiers(this.operation)
        ) {
          this.call_force_encoding(false)
        } else {
          const ok = await askUserConfirmation(
            __(T.misc.attention),
            this.getWithUserConfirmationMessage(errors, encodingValidationResponse)
          )
          if (ok) {
            this.call_force_encoding(false)
          } else {
            // this.clear()
            this.setState({ associationStatus: 'ERROR' })
            this.isEncoding = false
          }
        }
      } else {
        //if (!isSkipValidation(this.operation)) await this.startAntenna()
        const timer = this.operation.options.timeoutTagsNoReads ? this.operation.options.timeoutTagsNoReads : 5000
        if (timer > 0) {
          this.clearTimerNoReads()
          this.timerNoRead = setTimeout(async () => {
            //await this.stopAntenna()
            if (isSkipValidation(this.operation)) {
              this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, this.create)
            } else {
              this.feedbackSoundKo()
              this.setState({ associationStatus: 'ERROR' })
            }
          }, timer)
        }
      }
    }
  }

  addIdentifierRole = async (res) => {
    let idt
    if (
      res.encodingValidationResponse &&
      res.encodingValidationResponse.item &&
      res.encodingValidationResponse.item.identifiers
    ) {
      for (let i = 0; i < res.encodingValidationResponse.item.identifiers.length; i++) {
        idt = res.encodingValidation.identifiers.find(
          (e) => e.code === res.encodingValidationResponse.item.identifiers[i].code
        )
        if (idt) {
          idt.role = res.encodingValidationResponse.item.identifiers[i].role ?? ''
        }
      }
    }
  }

  addIdentifierConfiguration = async (res) => {
    let config
    if (res.encodingValidationResponse.configurationDetails)
      config = res.encodingValidationResponse.configurationDetails
    else if (res.encodingValidationResponse.configuration && res.encodingValidationResponse.configuration.details)
      config = res.encodingValidationResponse.configuration.details
    if (!config) config = []

    // added role if not present for each identifier based on configuration
    let cg
    for (let r = 0; r < res.encodingValidation.identifiers.length; r++) {
      if (!res.encodingValidation.identifiers[r].role) {
        cg = config.find((o) => o.identifierType === res.encodingValidation.identifiers[r].identifierType)
        if (cg) {
          res.encodingValidation.identifiers[r].role = cg.role
        }
      }
    }

    // filter identifiers only for status != IGNORED
    const identifiers = res.encodingValidation.identifiers.filter((e) => e._status !== 'IGNORED')

    const expectOnlyMandatoryIdentifiers = isExpectOnlyMandatoryIdentifiers(this.operation)
    let idt, serial, code, status, read
    for (let c = 0; c < config.length; c++) {
      if (!expectOnlyMandatoryIdentifiers || (expectOnlyMandatoryIdentifiers && !config[c].optional)) {
        idt = identifiers.find(
          (e) => e.identifierType === config[c].identifierType && e.role.toLowerCase() === config[c].role.toLowerCase()
        )
        if (!idt) {
          status = 'TO_BE_READ' //'PROCESSING'
          code = ''
          read = false
          //check if there is a serial number among the item identifiers
          if (config[c].identifierType === 'SIMPLE_ITEM_IDENTIFIER') {
            serial = res.encodingValidationResponse.item.identifiers.find(
              (e) => e.type === config[c].identifierType && e.role.toLowerCase() === config[c].role.toLowerCase()
            )
            if (serial && serial.code !== '') {
              status = 'CONFIRMED' //serial.status
              code = serial.code
              read = true
            }
          }

          res.encodingValidation.identifiers.push({
            code: code,
            identifierType: config[c].identifierType,
            role: config[c].role,
            _status: status,
            _read: read,
            _error: undefined,
          })
        }
      }
    }
  }

  clearTimerTags = () => {
    if (this.timerTags) {
      clearTimeout(this.timerTags)
      this.timerTags = null
    }
  }

  startTimerTags = () => {
    const timeoutValidateTags = this.operation.options.timeoutValidateTags ?? 1000
    this.clearTimerNoReads()
    this.clearTimerTags()
    this.timerTags = setTimeout(async () => {
      if (this.state.encodingValidation) {
        //await this.stopAntenna()
        this.validateReadTags()
      } else {
        this.validateIdentifiersTag()
      }
    }, timeoutValidateTags)
  }

  validateReadTags = async () => {
    if (this.isEncoding) return
    this.isEncoding = true
    const tmpTagsRead = this.state.tagsRead
    const tmpEncodingValidation = this.state.encodingValidation
    if (tmpEncodingValidation) {
      for (let t = 0; t < tmpTagsRead.length; t++) {
        await EncodingExtensions.onTagRead(tmpEncodingValidation, tmpTagsRead[t], this.operation)
      }
      try {
        const res = await EncodingExtensions.validate(tmpEncodingValidation)
        this.setState(
          {
            encodingValidationResponse: res.encodingValidationResponse,
            encodingValidation: tmpEncodingValidation,
          },
          this.onTagReadValidateResponse
        )
      } catch (error) {
        this.isEncoding = false
        this.onClear()
        this.encodingError(error, 'Unknown error during encoding validation')
      }
    } else {
      this.isEncoding = false
      this.onClear()
    }
  }

  checkIfForceCreate = (value) => {
    const { encodingValidationResponse } = this.state
    let canForce = false
    if (getCanForceAssocation(this.operation) === value) {
      const notForcible = encodingValidationResponse?.errors.find((e) => !e.forcible)
      //if only errors with forcible = true then force create
      canForce = !notForcible
    }
    return canForce
  }

  getWithUserConfirmationMessage = (errors, response?) => {
    let errorCheck = errors.find((e) => e.errorCode === 'ALREADY_ASSOCIATED_TAG')
    if (errorCheck) {
      return __(T.error.force_tag_associated)
    }
    errorCheck = errors.find((e) => e.errorCode === 'MISSING_MANDATORY_QC')
    if (errorCheck) {
      return __(T.error.missing_mandatory_qc)
    }
    errorCheck = errors.find((e) => e.errorCode === 'WRONG_QC_STATUS')
    if (errorCheck) {
      return __(T.error.wrong_qc_status)
    }
    errorCheck = errors.find((e) => e.errorCode === 'WRONG_QC_SIZE')
    if (errorCheck) {
      if (response.item && response.item.product && errorCheck.payload && errorCheck.payload.manualSize) {
        return __(T.error.wrong_qc_size_payload, {
          productSize: response.item.product.size?.value || 'n/a',
          manualSize: errorCheck.payload.manualSize || 'n/a',
        })
      }
      return __(T.error.wrong_qc_size)
    }
    errorCheck = errors.find((e) => e.errorCode === 'WRONG_QC_MODEL')
    if (errorCheck) {
      return __(T.error.wrong_qc_model)
    }
    return __(T.error.tags_errors)
  }

  call_force_encoding = (write) => {
    const { encodingCreateCounter } = this.state
    if (!write) {
      this.isEncoding = true
      this.setState({ forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
    } else {
      this.setState({ forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
    }
  }

  // // callback response validate for flow identifier
  // validationReadTags = async () => {
  //   const { encodingValidationResponse, encodingCreateCounter } = this.state
  //   if (encodingValidationResponse) {
  //     if (encodingValidationResponse.success) {
  //       this.isEncoding = true
  //       this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
  //     }
  //   } else {
  //     this.feedbackSoundKo()
  //     this.setState({ processing: false })
  //     this.isEncoding = false
  //   }
  // }

  // checkRestartAntenna = async () => {
  //   const { encodingValidation } = this.state
  //   let restart = true
  //   const identifiers = encodingValidation?.identifiers ?? []
  //   let i = 0
  //   while (i < identifiers.length && restart) {
  //     if (identifiers[i]._status === 'ERROR') restart = false
  //     i++
  //   }
  //   if (restart) {
  //     await this.startAntenna()
  //   }
  // }

  onTagRead = async (tag: TmrTag) => {
    const { encodingValidation, encodingValidationResponse, targetTag, associationStatus } = this.state
    if (!encodingValidation || !encodingValidation.identifiers) return
    if (this.isWrongTag) {
      if (tag.epc) console.log('UHF discard by wrong tag', tag.epc)
      return
    }

    if (tag.epc) console.log('UHF used tag', tag.epc)

    //da verificare in base ai controlli sottostanti
    RfidReader.setAutomaticStop(false)
    RfidReader.stopTimer()

    //se leggo uhf e ho nfc con decode in corso scarto tag e lo rimuovo dal reader
    const nfcRead = encodingValidation?.identifiers.find((idt) => idt.identifierType === 'NFC_TAG')
    if (tag.epc && this.isDecodeNfc && nfcRead) {
      RfidReader.removeTags([tag.epc])
      return
    }

    if (
      this.state.encodingValidation?.identifiers.some(
        (id) => id.identifierType === 'UHF_TAG' && id._status === 'ERROR' && id._error === __(T.error.wrong_tag)
      )
    ) {
      console.log('Ignoring tag', tag.uid ?? tag.epc, 'due to wrong tag error')
      this.setState({ processing: false })
      return
    }

    if (
      tag.uid &&
      !encodingValidationResponse?.configuration?.details.some((conf) => conf.identifierType === 'NFC_TAG')
    ) {
      this.setState({ processing: false })
      return
    }

    this.isEncoding = true
    if (tag.uid) console.log('Entering encoding for NFC ', tag.uid)
    else if (tag.epc) console.log('Entering encoding for UHF ', tag.epc)

    if (tag.epc && tag.epc !== targetTag) {
      if (
        encodingValidation.identifiers.some(
          (id) => id.identifierType === 'UHF_TAG' && id.code === targetTag && id._status === 'CONFIRMED'
        )
      ) {
        // the correct tag was already read. We'll have an "extra tag" error
        console.log('UHF discard alreay confirmed', tag.epc)
        return
      } else {
        this.isWrongTag = true
        console.log('UHF wrong tag', tag.epc)
        const newEncodingValidation = { ...encodingValidation }
        newEncodingValidation.identifiers.forEach((element) => {
          if (element.identifierType === 'UHF_TAG') {
            element._status = 'ERROR'
            element._error = __(T.error.wrong_tag)
          } else if (element.identifierType === 'NFC_TAG') {
            element._status = 'ERROR'
            element._error = ''
          }
        })
        this.setState({ encodingValidation: newEncodingValidation, processing: false, associationStatus: 'ERROR' })
        showToastError(__(T.error.wrong_tag), __(T.error.error), this.isModal)
        this.isEncoding = false
        await this.stopAntenna()
        this.clearTimerReader()
        datadogRum.addAction('wrongUhfTagRead', { expected: targetTag, read: tag.epc })
        return
      }
    }
    try {
      this.clearTimerReader()
      this.clearTimer()
      const nfcRequiresMutualAuth =
        this.operation.attributes['nfc-requires-mutual-authentication'] &&
        this.operation.attributes['nfc-requires-mutual-authentication'] === 'true' &&
        encodingValidationResponse?.configuration?.details.some((conf) => conf.identifierType === 'NFC_TAG')
      if (tag.uid && nfcRequiresMutualAuth) {
        this.isDecodeNfc = true
        this.setState({ processing: 'nfcDecryption' })
      }

      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.operation)
      const nfcError = encodingValidation.identifiers.some(
        (id) => id.identifierType === 'NFC_TAG' && id._status === 'ERROR'
      )
      if (tag.uid && nfcRequiresMutualAuth) {
        console.log('NFC decoded as ', tag.uid)
        this.setState(
          {
            processing: false,
            associationStatus: nfcError ? 'ERROR' : associationStatus,
          },
          () => {
            if (nfcError) {
              this.isEncoding = false
              this.isDecodeNfc = false
              this.stopAntenna()
              this.clearTimer()
              this.clearTimerReader()
            }
          }
        )
      }
      const nfcCollision = encodingValidation.identifiers.some(
        (id) => id.identifierType === 'NFC_TAG' && id._status === 'NFC_COLLISION'
      )
      this.setState({ encodingValidation }, () => {
        if (nfcCollision) {
          this.setState({ processing: false, associationStatus: 'ERROR' })
          this.isEncoding = false
          this.isDecodeNfc = false
          this.stopAntenna()
          this.clearTimer()
          this.clearTimerReader()
        } else {
          this.onTagReadTimer()
        }
      }) // chiamare solo se non NFC Collision, altrimenti metti retry
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ processing: false })
      this.isEncoding = false
      this.isDecodeNfc = false
    }
  }

  onTagReadTimer = () => {
    this.timer = setTimeout(() => {
      this.onTagReadValidate()
    }, this.operation.options.timeoutValidateTags ?? 700)
  }

  onTagReadValidate = async () => {
    try {
      this.isEncoding = true
      console.log('Validating encoding', this.state.encodingValidation)
      const res = await EncodingExtensions.validate(this.state.encodingValidation!)
      // if (
      //   res.encodingValidationResponse.errors?.length > 1 &&
      //   (res.encodingValidationResponse.errors?.every((e) => e.errorCode === 'ALREADY_ASSOCIATED_TAG') ||
      //     res.encodingValidationResponse.errors?.every((e) => e.errorCode === 'MISSING_MANDATORY_TAG'))
      // ) {
      //   console.log('Ignoring "already fully associated" verify result')
      //   return
      // }
      if (
        this.state.encodingValidation?.identifiers.some(
          (id) => id.identifierType === 'UHF_TAG' && id._status === 'ERROR' && id._error === __(T.error.wrong_tag)
        )
      ) {
        console.log('Ignoring tags due to wrong tag error')
        return
      }
      if (
        res.encodingValidationResponse.configuration?.details.filter((conf) => conf.identifierType === 'NFC_TAG')
          .length === 1
      ) {
        if (res.encodingValidationResponse.item.identifiers.filter((id) => id.type === 'NFC_TAG').length > 1) {
          if (
            res.encodingValidationResponse.item.identifiers.some(
              (id) => id.type === 'NFC_TAG' && id.status === 'CONFIRMED'
            ) &&
            res.encodingValidationResponse.item.identifiers.some((id) => id.type === 'NFC_TAG' && id.status === 'ERROR')
          ) {
            res.encodingValidationResponse.item.identifiers = res.encodingValidationResponse.item.identifiers.filter(
              (id) => id.type !== 'NFC_TAG' || id.status !== 'ERROR'
            )
          }
        }
      }
      if (this.state.associationStatus === 'CONFIRMED') {
        this.isEncoding = false
        return
      }
      this.setState(
        {
          encodingValidationResponse: res.encodingValidationResponse,
          encodingValidation: res.encodingValidation,
        },
        this.onTagReadValidateResponse
      )
    } catch (error) {
      this.encodingError(error, 'Unknown error during encoding validation')
    } finally {
      this.isEncoding = false
    }
  }

  feedbackSoundKo = () => {
    if (this.operation.options.enableSoundFeedbackKo && this.operation.options.enableSoundFeedbackKo === 'true') {
      Sounds.error()
    }
  }

  encodingError = (error?: any, text?: string) => {
    this.feedbackSoundKo()
    showToastError(
      error && error.message ? error.message : text ?? 'Unknown error during encoding validation',
      __(T.error.error),
      this.isModal
    )
  }

  onValidationOperationNone = async () => {
    await this.stopAntenna()
    this.setState({ processing: false, associationStatus: 'SKIPPED' })
    showToast({ status: 'success', description: __(T.messages.encoding_success) })
    if (this.operation.options.enableSoundFeedbackOk && this.operation.options.enableSoundFeedbackOk === 'true') {
      Sounds.success()
    }
    setTimeout(async () => {
      this.resetAfterCreate()
    }, this.operation.options.resetReadingsAfterOperation || 300)
  }

  // callback response validate on flow product and order
  onTagReadValidateResponse = async () => {
    const { encodingValidationResponse, encodingCreateCounter, associationStatus } = this.state

    if (encodingValidationResponse) {
      if (encodingValidationResponse.operationToPerform === 'NONE') {
        await this.onValidationOperationNone()
      } else if (
        encodingValidationResponse.operationToPerform === 'UPDATE_ONLY_PRODUCT' ||
        encodingValidationResponse.operationToPerform === 'UPDATE_WAM'
      ) {
        const ok = await askUserConfirmation(__(T.misc.attention), __(T.misc.update_only_product))
        if (ok) {
          this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
        } else {
          if (getAskUserInputEveryTime(this.operation)) {
            await this.stopAntenna()
          }
          // this.clear()
          this.setState({ associationStatus: 'ERROR' })
          this.isEncoding = false
        }
      } else if (encodingValidationResponse.operationToPerform === 'FAIL') {
        // this.setState({ associationStatus: 'ERROR' })
        // this.feedbackSoundKo()
        const immediateError = encodingValidationResponse.errors.some((e) => e.errorCode === 'MISSING_SYSTEM_TAG')
        this.isEncoding = false
        this.setState({ processing: false, associationStatus: immediateError ? 'ERROR' : associationStatus }, () => {
          if (!immediateError) {
            this.attachAutomaticReaderStop()
          } else {
            this.stopAntenna()
          }
        })
      } else if (
        encodingValidationResponse.operationToPerform === 'FORCE_ITEM_CREATION' ||
        encodingValidationResponse.operationToPerform === 'REWRITE_CONFIGURATION' ||
        encodingValidationResponse.operationToPerform === 'UPDATE_ITEM'
      ) {
        this.clearTimerReader()
        this.stopAntenna()
        this.checkEncodingForceOption()
      } else {
        this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
      }
    } else {
      this.feedbackSoundKo()
      this.setState({ processing: false })
      this.isEncoding = false
    }
  }

  checkEncodingForceOption = async () => {
    const { encodingValidationResponse } = this.state
    if (encodingValidationResponse) {
      const initialType = getInitialType(this.operation)
      const encodingWrite = initialType !== 'identifier'
      if (this.checkIfForceCreate('yes')) {
        this.call_force_encoding(encodingWrite)
      } else if (this.checkIfForceCreate('withUserConfirmation')) {
        const errors = encodingValidationResponse.errors ?? []
        // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
        if (
          errors.length == 1 &&
          errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
          isExpectOnlyMandatoryIdentifiers(this.operation)
        ) {
          this.call_force_encoding(encodingWrite)
        } else {
          const ok = await askUserConfirmation(
            __(T.misc.attention),
            this.getWithUserConfirmationMessage(errors, encodingValidationResponse)
          )
          if (ok) {
            this.call_force_encoding(encodingWrite)
          } else {
            // this.clear()
            this.setState({ associationStatus: 'ERROR' })
            this.isEncoding = false
          }
        }
      } else {
        if (encodingWrite) {
          this.checkAttachAutomaticReaderStop(encodingValidationResponse)
        } else {
          const timer = this.operation.options.timeoutTagsNoReads ? this.operation.options.timeoutTagsNoReads : 5000
          if (timer > 0) {
            this.clearTimerNoReads()
            this.timerNoRead = setTimeout(async () => {
              //await this.stopAntenna()
              if (isSkipValidation(this.operation)) {
                this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, this.create)
              } else {
                this.feedbackSoundKo()
                this.setState({ associationStatus: 'ERROR' })
              }
            }, timer)
          }
        }
      }
    } else {
      this.feedbackSoundKo()
      this.setState({ processing: false })
      this.isEncoding = false
    }
  }

  checkAttachAutomaticReaderStop = async (encodingValidationResponse) => {
    this.isEncoding = false
    if (this.operation.autostopAntennaTimeout > 0) {
      const blockedErrors = encodingValidationResponse
        ? encodingValidationResponse.errors.filter((e) => BLOCKED_ERRORS.includes(e.errorCode))
        : []
      if (blockedErrors.length === 0) {
        this.attachAutomaticReaderStop()
      } else {
        //await this.stopAntenna()
        this.feedbackSoundKo()
        this.setState({ associationStatus: 'ERROR' })

        /*setTimeout(async () => {
          this.resetAfterCreate()
        }, this.operation.options.resetReadingsAfterOperation || 300)*/
      }
    }
  }

  create = async (noAntennasStart = false) => {
    const { forceAssociation, pin, encodingValidation, encodingValidationResponse, tagsRead } = this.state
    if (encodingValidation) {
      await this.stopAntenna()
      const skipValidation = isSkipValidation(this.operation)
      try {
        let res
        if (forceAssociation) res = await EncodingExtensions.force_create(encodingValidation!, pin)
        else res = await EncodingExtensions.create(encodingValidation!)
        if (!res) throw new Error(__(T.error.item_creation_error))
        if (!skipValidation) {
          this.setState({
            encodingCreateResponse: res,
            processing: false,
            associationStatus: getEncodingAssociationStatus(res, encodingValidationResponse),
          })
        } else {
          await EncodingExtensions.set_identifiers_status(encodingValidation, res)
          this.setState({
            encodingCreateResponse: res,
            encodingValidationResponse: res,
            encodingValidation: encodingValidation,
            processing: false,
            associationStatus: getEncodingAssociationStatus(res, encodingValidationResponse),
          })
        }
        if (res.success) {
          const encodingInfo = {
            durationMs: new Date().getTime() - this.ddFullEncodeTime,
            type: encodingValidation?.identifiers.some((id) => id.identifierType === 'NFC_TAG')
              ? 'RFID_WITH_NFC'
              : 'RFID_ONLY',
          }
          if (__isDev) console.log('Encoding info', encodingInfo)
          datadogRum.addAction('fullEncodeTime', encodingInfo)
          await AppStore.increaseDailyEncodedItems(this.operation.code)
          showToast({ status: 'success', description: __(T.messages.encoding_success) })
          if (this.operation.options.enableSoundFeedbackOk && this.operation.options.enableSoundFeedbackOk === 'true') {
            Sounds.success()
          }
        } else {
          this.encodingError(undefined, __(T.error.item_creation_error))
        }
        this.isEncoding = false
        //if success reset operation after time in milliseconds
        if (res.success) {
          setTimeout(async () => {
            this.resetAfterCreate()
          }, this.operation.options.resetReadingsAfterOperation || (skipValidation ? 4000 : 300))
        } else {
          if (!noAntennasStart && !forceAssociation) {
            await this.startAntenna()
          }
          this.setState({ processing: false })
        }
      } catch (error) {
        if (getInitialType(this.operation) !== 'identifier') {
          this.setState({ processing: false })
          this.attachAutomaticReaderStop()
        } else {
          this.removeTagReadFromReader(tagsRead)
          this.closeVirginTagModal()
        }
        if (!noAntennasStart && !forceAssociation) {
          await this.startAntenna()
        }
        this.isEncoding = false
        this.encodingError(error, 'Unknown error during encoding validation')
      }
    } else {
      this.setState({ processing: false })
      this.encodingError(undefined, __(T.error.item_creation_error))
    }
  }

  resetAfterCreate = async () => {
    if (getInitialType(this.operation) !== 'identifier') {
      this.clearReads(true, false)
    } else {
      this.onClear(true)
    }
  }

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  clear = () => {
    if (getInitialType(this.operation) !== 'identifier') {
      this.clearReads(false, false)
    } else {
      this.onClear()
    }
  }

  onClear = async (addtags = false) => {
    this.clearTimer()
    if (getInitialType(this.operation) !== 'identifier') {
      await this.stopAntenna()
    } else {
      this.clearTimerTags()
      this.clearTimerNoReads()
      await this.startAntenna()
    }
    RfidReader.clear()
    if (addtags && this.state.encodingValidation) {
      RfidReader.tags = this.state.encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map((idf) => ({ epc: idf.code, tid: idf.code } as any))
    }
    this.setState(
      {
        formSchemaData: { product: { code: '' }, wam: '' },
        tagsRead: [],
        associationStatus: 'TO_BE_READ',
        pin: '',
        forceAssociation: false,
        encodingCreateCounter: 0,
        processing: false,
      },
      this.resetValidateResponse
    )
  }

  clearReads = async (addtags = false, noask = true) => {
    const { encodingValidation, resetFormCounter, targetTag } = this.state
    this.clearTimer()
    RfidReader.clear()
    if (addtags && encodingValidation) {
      RfidReader.tags = encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map((idf) => ({ epc: idf.code, tid: idf.code } as any))
    }

    if (getAskUserInputEveryTime(this.operation) && !noask) {
      RfidReader.setAutomaticStop(false)
      await this.stopAntenna()
      this.setState(
        {
          formSchemaData: { product: { code: '' }, wam: '' },
          associationStatus: 'TO_BE_READ',
          tagsRead: [],
          resetFormCounter: resetFormCounter + 1,
          pin: '',
          forceAssociation: false,
          encodingCreateCounter: 0,
          numWriteAttempt: 0,
          targetTag: targetTag ?? '',
          serial: '',
          processing: false,
        },
        this.resetValidateResponse
      )
    } else {
      RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)

      const res = await EncodingExtensions.getItemConfiguration(this.operation, this.state.formSchemaData)
      // keep serial
      const serial = encodingValidation?.identifiers.find((idf) => idf.identifierType === 'SIMPLE_ITEM_IDENTIFIER')
      if (serial) serial._status = 'SKIPPED'

      this.setState(
        {
          encodingValidation: {
            ...res.encodingValidation,
            identifiers: [
              ...res.encodingValidation.identifiers
                .filter((idf) => idf.identifierType !== 'SIMPLE_ITEM_IDENTIFIER')
                .concat(serial ? [serial] : []),
            ],
          },
          encodingValidationResponse: res.encodingValidationResponse,
          counters: res.counters,
          encodingCreateResponse: undefined,
          associationStatus: 'TO_BE_READ',
          tagsRead: [],
          pin: '',
          forceAssociation: false,
          encodingCreateCounter: 0,
          numWriteAttempt: 0,
          targetTag: targetTag ?? '',
          serial: '',
          processing: false,
        },
        async () => {
          this.isWrongTag = false
          await RfidReader.stopWsCustomCommands()
          if (!addtags) {
            await this.startAntenna()
          }
        }
      )
    }
  }

  resetValidateResponse = () => {
    this.setState({
      encodingValidation: undefined,
      encodingValidationResponse: undefined,
      counters: undefined,
      encodingCreateResponse: undefined,
    })
  }

  managerPinModal = () => {
    this.setState({ showPin: !this.state.showPin })
  }

  forceWithPin = (pin) => {
    this.setState({ pin: pin, forceAssociation: true, showPin: false }, this.create)
  }

  getEncodingProduct = () => {
    const { encodingValidationResponse } = this.state
    let product
    if (encodingValidationResponse && encodingValidationResponse.item) {
      product = encodingValidationResponse.item.product
      if (
        enabledWamGroupedProduct() &&
        encodingValidationResponse.item.attributes &&
        encodingValidationResponse.item.attributes.wam
      ) {
        if (!product.attributes) {
          product.attributes = {}
        }
        product.attributes.wam = encodingValidationResponse.item.attributes.wam
      }
    }
    return product
  }

  onTabClick = async (tab) => {
    if (tab === 'verify' && !this.isEncoding) {
      this.setState({ processing: true }, this._changeTab)
    }
  }

  _changeTab = async () => {
    RfidReader.clear()
    await this.stopAntenna()
    this.timer = setTimeout(() => {
      Router.navigate(
        `/encoding/:opCode/verify`,
        {
          opCode: this.operation.code,
        },
        {
          state: {
            originTemplatePath: this.operation.templatePath,
          },
        }
      )
    }, this.operation.options.antennaTurnWaitingInterval ?? 1000)
  }

  fetchPrintTemplate = async () => {
    if (AppStore.zbpEmulation) return
    if (!this.operation.attributes['zpl-template-code']) {
      showToastError(__(T.error.zplTemplateNotDefined), __(T.error.error), this.isModal)
      return
    }
    try {
      const printTemplate: Template = await Templates.getByCode(this.operation.attributes['zpl-template-code'])
      this.setState({ printTemplate })
    } catch (error: any) {
      showToastError(`Template: ${error.message}`, __(T.error.error), this.isModal)
    }
  }

  getPrinters = async () => {
    if (AppStore.zbpEmulation) return []
    try {
      const defaultPrinter = await this.browserPrint.getDefaultPrinter()
      const availablePrinters: Device[] = await this.browserPrint.getAvailablePrinters()
      let printerToSelect = defaultPrinter
      const lastUsedPrinterUid = await AppStore.getZbpSelectedPrinterUid()
      if (lastUsedPrinterUid && availablePrinters.some((p) => p.uid === lastUsedPrinterUid)) {
        printerToSelect = availablePrinters.find((p) => p.uid === lastUsedPrinterUid)!
      }
      this.browserPrint.setPrinter(printerToSelect)
      const printerToSelectStatus = await this.browserPrint.checkPrinterStatus()
      if (!printerToSelectStatus.isReadyToPrint)
        showToastError(`${printerToSelect.name}: ${printerToSelectStatus.errors}`, __(T.error.error), this.isModal)
      this.setState({
        availablePrinters: availablePrinters.map((device) => ({
          device,
          isDefault: device.uid === defaultPrinter.uid,
          isReadyToPrint: device.uid === printerToSelect.uid ? printerToSelectStatus.isReadyToPrint : undefined,
          errors: device.uid === printerToSelect.uid ? printerToSelectStatus.errors : undefined,
        })),
        selectedPrinterUid: printerToSelect.uid,
      })
    } catch (error: any) {
      console.error(error)
      if (error.message.includes('Failed to fetch')) {
        showToastError(__(T.error.zbpNotFound), __(T.error.error), this.isModal)
      } else {
        showToastError(`${error.message}. Please check Zebra Browser Print agent`, __(T.error.error), this.isModal)
      }
      this.setState({ availablePrinters: [], selectedPrinterUid: undefined })
    }
  }

  updateAvailablePrinter = (printerInfo: DeviceInfo, printerStatus: DeviceStatus, andSelect?: boolean) => {
    const { availablePrinters, selectedPrinterUid } = this.state
    const selectedPrinterIsTheDefaultOne =
      availablePrinters.find((p) => p.isDefault)?.device.uid === printerInfo.device.uid
    this.setState({
      availablePrinters: [
        ...availablePrinters
          .filter((p) => p.device.uid !== printerInfo.device.uid)
          .map((p) => ({ ...p, isReadyToPrint: undefined })),
        {
          device: printerInfo.device,
          isDefault: selectedPrinterIsTheDefaultOne,
          isReadyToPrint: printerStatus.isReadyToPrint,
          errors: printerStatus.errors,
        },
      ],
      selectedPrinterUid: andSelect ? printerInfo.device.uid : selectedPrinterUid,
    })
  }

  onPrinterSelected = async (v: DeviceInfo) => {
    try {
      this.browserPrint.setPrinter(v.device)
      const printerStatus = await this.browserPrint.checkPrinterStatus()
      if (!printerStatus.isReadyToPrint)
        showToastError(`${v.device.name}: ${printerStatus.errors}`, __(T.error.error), this.isModal)
      await AppStore.setZbpSelectedPrinterUid(v.device.uid)
      this.updateAvailablePrinter(v, printerStatus, true)
    } catch (e) {
      showToastError(e, __(T.error.error), this.isModal)
    }
  }

  sendZbpPrint = async () => {
    if (AppStore.zbpEmulation) {
      showToast({
        title: 'Info',
        description: 'Print sent to mocked Browser Printer.',
        status: 'success',
      })
      return
    }
    const { availablePrinters, selectedPrinterUid, printTemplate, targetTag, serial, encodingValidationResponse } =
      this.state
    try {
      const selectedPrinter = availablePrinters.find((p) => p.device.uid === selectedPrinterUid)
      if (!selectedPrinter) return
      if (!encodingValidationResponse || !encodingValidationResponse.item.product) {
        showToastError(__(T.identifier_error.MISSING_PRODUCT_INFO), __(T.error.error), this.isModal)
        return
      }
      if (!printTemplate) {
        showToastError(__(T.error.error), __(T.error.no_print_template), this.isModal)
        return
      }
      let printerStatus = await this.browserPrint.checkPrinterStatus()
      if (!printerStatus.isReadyToPrint) {
        showToastError(`${selectedPrinter.device.name}: ${printerStatus.errors}`, __(T.error.error), this.isModal)
      } else {
        const product = encodingValidationResponse.item.product
        const ws = await AppStore.getDefaultWorkstation()
        if (!ws) return
        const wsPlace: TmrPlace = await Places.get(ws.placeId)

        const dataMatrix = `0_${serial}_0010_${product.code}__${wsPlace.code}_${wsPlace.countryCode ?? ''}__`
        const zpl = printTemplate.templateBody
          .replace('[EPC]', targetTag)
          .replace('[SHORTSKU]', product.code)
          .replace('[PRODUCTNAME]', product.description ?? '')
          .replace('[SERIAL_NUMBER]', serial)
          .replace('[DATAMATRIX]', dataMatrix)
        await this.browserPrint.print(zpl)
        printerStatus = await this.browserPrint.checkPrinterStatus()
        if (!printerStatus.isReadyToPrint) {
          showToastError(`${selectedPrinter.device.name}: ${printerStatus.errors}`, __(T.error.error), this.isModal)
        }
      }
      this.updateAvailablePrinter(selectedPrinter, printerStatus)
    } catch (e) {
      showToastError(e, __(T.error.error), this.isModal)
    }
  }

  getSelectDeviceLabel = (deviceInfo?: DeviceInfo) => {
    if (!deviceInfo) return ''
    return `${deviceInfo.device.name}${deviceInfo.isDefault ? ' (Default) ' : ''}${
      deviceInfo.isReadyToPrint === true
        ? ` - ${__(T.messages.readyf)}`
        : deviceInfo.isReadyToPrint === false
        ? ` - ${__(T.messages.not_ready)}`
        : ''
    }`
  }

  render() {
    const {
      encodingValidation,
      showPin,
      formSchemaData,
      encodingValidationResponse,
      counters,
      processing,
      associationStatus,
      antennaOn,
      starting,
      resetFormCounter,
      availablePrinters,
      selectedPrinterUid,
    } = this.state
    const identifiers = encodingValidation?.identifiers
    const initialType = getInitialType(this.operation)
    const hasRfidAndSerial = isRfidAndSerial(this.operation) && !isSkipValidation(this.operation)

    return (
      <Page
        headerRight={
          <EncodingRightHeader
            onTabClick={this.onTabClick}
            antennaOn={antennaOn}
            operation={this.operation}
            options={this.state.options}
          >
            <Box style={{ marginLeft: 20, marginRight: 20, justifyContent: 'center' }}>
              {AppStore.zbpEmulation ? (
                <Text>MOCKED PRINTER</Text>
              ) : availablePrinters && availablePrinters.length > 0 ? (
                <FormSchemaForm
                  key="printer"
                  flex
                  style={{ minWidth: 340 }}
                  schema={[
                    {
                      type: 'select',
                      name: 'printer',
                      label: __(T.misc.printer),
                      required: true,
                      options: availablePrinters.map((p) => ({
                        value: p.device.uid,
                        label: this.getSelectDeviceLabel(p),
                      })),
                      defaultValue: {
                        value: availablePrinters.find((p) => p.device.uid === selectedPrinterUid)?.device.uid,
                        label: this.getSelectDeviceLabel(
                          availablePrinters.find((p) => p.device.uid === selectedPrinterUid)
                        ),
                      },
                      onChange: (selected) => {
                        if (!selected) return this.setState({ selectedPrinterUid: undefined })
                        const selectedPrinter = availablePrinters.find((p) => p.device.uid === selected.value)
                        if (!selectedPrinter) return
                        this.onPrinterSelected(selectedPrinter)
                      },
                    },
                  ]}
                  onSubmit={(data: any) => {
                    console.log(data)
                    this.onPrinterSelected(data)
                  }}
                />
              ) : (
                <Spinner width={10} height={10} />
              )}
            </Box>
          </EncodingRightHeader>
        }
        title={this.operation.description}
        enableEmulation
      >
        {initialType === 'identifier' && (
          <>
            {encodingValidation && encodingValidationResponse && (
              <Page.Sidebar width={500}>
                <EncodingProduct
                  product={this.getEncodingProduct()}
                  clear={this.onClear}
                  fields={getEncodingProductFields()}
                />
              </Page.Sidebar>
            )}
            <Page.Content notBoxed>
              {(!encodingValidation || !encodingValidationResponse) && (
                <EncodingReading
                  starting={starting}
                  startAntenna={this.startAntenna}
                  antennaOn={antennaOn}
                  message={
                    antennaOn
                      ? __(T.messages.bring_an_item_to_antenna)
                      : __(T.messages.connection_to_station_in_progress)
                  }
                  identifier={
                    hasRfidAndSerial
                      ? {
                          identifierType: 'SIMPLE_ITEM_IDENTIFIER',
                          _status: 'TO_BE_READ',
                        }
                      : undefined
                  }
                  onInputSubmit={hasRfidAndSerial ? (barcode) => this.verifySerial({ barcode } as any) : undefined}
                />
              )}
              {encodingValidation && encodingValidationResponse && (
                <EncodingIdentifers
                  identifiers={identifiers}
                  clearReads={this.clear} //this.checkForceAssociation
                  onTagRead={this.onTagReadIdentifier}
                  encodingValidation={encodingValidation}
                  associationStatus={associationStatus}
                  disabledRetry={false}
                />
              )}
            </Page.Content>
          </>
        )}
        {initialType !== 'identifier' && (
          <>
            <Page.Sidebar width={500}>
              <Box style={{ flex: 1 }}>
                <EncodingForm
                  product={this.getEncodingProduct()}
                  data={formSchemaData}
                  operation={this.operation}
                  onSubmit={this.onSubmitForm}
                  onProductionOrderRowClear={this.onClear}
                  onIdentifierClear={this.onClear}
                  counters={counters}
                  resetStateCounter={resetFormCounter}
                />
              </Box>
            </Page.Sidebar>
            <Page.Content notBoxed>
              {identifiers && encodingValidation && (
                <EncodingIdentifers
                  identifiers={identifiers}
                  clearReads={this.clearReads}
                  onTagRead={this.onTagRead}
                  encodingValidation={encodingValidation}
                  associationStatus={associationStatus}
                  disabledRetry={false}
                />
              )}
              {!identifiers && (
                <Box borderRadius={15} ph={40} flex bg="white" center>
                  <Text center style={{ width: 500 }} fontSize={30}>
                    {__(T.messages.fill_all_fields_and_search_an_item_configuration)}
                  </Text>
                </Box>
              )}
            </Page.Content>
          </>
        )}
        {processing && (
          <FullLoadingLayer
            message={__(processing === 'nfcDecryption' ? T.messages.nfcDecryption : T.messages.operation_in_progress)}
          />
        )}

        {showPin && (
          <InputModal
            title={__(T.titles.pin_modal)}
            labelInput="Pin"
            onClose={() => this.managerPinModal()}
            onConfirm={this.forceWithPin}
          />
        )}
      </Page>
    )
  }
}
