import { Component } from 'react'
import {
  TmrProduct,
  ProductionOrder,
  ProductionOrderRow,
  ProductionOrders,
  ProductionOrderRows,
  EncodingCounters,
} from 'stylewhere/api'
import { Box, FormSchemaForm, InfoBox, Spacer, EncodingProduct } from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import { FormSchemaData } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { showToastError, getEncodingProductFields, getInitialType } from 'stylewhere/shared/utils'

interface Props {
  operation: EncodingOperationConfig
  onSubmit: (data: FormSchemaData) => void
  onProductionOrderRowClear?: () => void
  onIdentifierClear?: () => void
  data?: FormSchemaData
  product?: TmrProduct
  counters?: EncodingCounters
  resetStateCounter?: number
}

interface State {
  data?: FormSchemaData
  productionOrder?: ProductionOrder
  productionOrderRow?: ProductionOrderRow
  resetStateCounter?: number
}

export class EncodingForm extends Component<Props, State> {
  productionOrderFields = [{ label: 'Order type', path: 'orderType' }]

  state: State = {
    data: this.props.data,
    resetStateCounter: this.props.resetStateCounter || 1,
  }

  shouldComponentUpdate = (nextProps) => {
    const { data, resetStateCounter } = this.state
    if (nextProps.data && JSON.stringify(nextProps.data) !== JSON.stringify(data)) {
      if(nextProps.resetStateCounter && resetStateCounter !== nextProps.resetStateCounter) {
        const initialType = getInitialType(this.props.operation)
        if(initialType === 'order') {
          this.setState({ data: nextProps.data, resetStateCounter: nextProps.resetStateCounter, productionOrder: undefined,  productionOrderRow: undefined })
        } else {
          this.setState({ data: nextProps.data, resetStateCounter: nextProps.resetStateCounter, productionOrderRow: undefined })
        }
      } else {
        this.setState({ data: nextProps.data })
      }      
    } else if(nextProps.resetStateCounter && resetStateCounter !== nextProps.resetStateCounter) {
      const initialType = getInitialType(this.props.operation)
      if(initialType === 'order') {
        this.setState({ resetStateCounter: nextProps.resetStateCounter, productionOrder: undefined,  productionOrderRow: undefined })
      } else {
        this.setState({ resetStateCounter: nextProps.resetStateCounter, productionOrderRow: undefined })
      }
    }
    return true
  }

  initialTypeProduct = () => {
    const { data } = this.state
    return (
      <FormSchemaForm
        flex
        initialValues={data}
        style={{ justifyContent: 'space-between' }}
        schema={EncodingExtensions.formSchema(this.props.operation, data)}
        onSubmit={this.props.onSubmit}
        submitText={__(T.misc.next)}
      />
    )
  }

  clearProductInfo = () => {
    this.props.onProductionOrderRowClear?.()
    if (this.state.data?.productionOrder)
      this.setState({
        productionOrderRow: undefined,
        data: { productionOrder: this.state.data.productionOrder },
      })
    else {
      this.setState({ productionOrderRow: undefined })
    }
  }

  productInfoBox = () => {
    const { product, counters, onIdentifierClear, operation } = this.props
    const { productionOrderRow, data } = this.state
    return (
      <EncodingProduct
        initialType={getInitialType(operation)}
        wam={data.wam}
        operationId={operation.id}
        product={
          {
            ...(product ?? productionOrderRow!.product),
            productionOrderRow: productionOrderRow?.code,
          } as any
        }
        clear={onIdentifierClear}
        productClose={this.clearProductInfo}
        fields={
          productionOrderRow?.code
            ? [{ label: __(T.misc.production_order_row), path: 'productionOrderRow' }, ...getEncodingProductFields()]
            : getEncodingProductFields()
        }
        counters={counters}
      />
    )
  }

  formSchemaProductionOrder = () => (
    <FormSchemaForm
      key="formProductionOrder"
      flex
      style={{ justifyContent: 'space-between' }}
      schema={EncodingExtensions.formSchema(this.props.operation, this.state.data)}
      onSubmit={this.submitProductionOrder}
      submitText={__(T.misc.next)}
    />
  )

  formSchemaProductionOrderRow = () => {
    const initialType = getInitialType(this.props.operation)

    const { productionOrder, productionOrderRow } = this.state
    return (
      <Box flex>
        {initialType !== 'orderRow' && (
          <>
            <InfoBox
              title={'Work Order'}
              subtitle={productionOrder?.code}
              data={productionOrder}
              fields={this.productionOrderFields}
              onCloseClick={() =>
                this.setState({ productionOrder: undefined, productionOrderRow: undefined, data: {} })
              }
            />
            <Spacer height={'10'} />
          </>
        )}
        {!!productionOrderRow && this.productInfoBox()}
        {!productionOrderRow && (
          <FormSchemaForm
            key="formProductionOrderRow"
            flex
            style={{ justifyContent: 'space-between' }}
            schema={EncodingExtensions.formSchema(this.props.operation, this.state.data)}
            onSubmit={this.submitProductionOrderRow}
            // initialValues={this.state.data}
            submitText={__(T.misc.next)}
          />
        )}
      </Box>
    )
  }

  submitProductionOrder = async (data: { productionOrder: string }) => {
    try {
      if (data.productionOrder) {
        const res = await ProductionOrders.search<ProductionOrder>({ equalCodes: data.productionOrder })
        if (res.content.length === 0) throw new Error('Production order not found')
        this.setState({ data, productionOrder: res.content[0] })
      }
    } catch (error) {
      showToastError(__(T.error.couldn_find_production_order))
    }
  }

  submitProductionOrderRow = async (data: { productionOrder: string; productionOrderRow: string }) => {
    const { productionOrder } = this.state
    try {
      let productionOrderRow
      const mode = this.getOrderSelectionMode()
      const initialType = getInitialType(this.props.operation)
      const productionOrderCodes = initialType !== 'orderRow' ? [data.productionOrder] : []
      const param =
        mode === 'byOrderAndSku'
          ? {
              equalProductionOrderCodes: productionOrderCodes,
              equalProductCodes: data.productionOrderRow,
            }
          : {
              equalProductionOrderCodes: productionOrderCodes,
              equalCodes: data.productionOrderRow,
            }
      const res = await ProductionOrderRows.search<ProductionOrderRow>(param)
      if (res && res.content && res.content.length > 0) {
        productionOrderRow = res.content[0]
        this.setState({ data, productionOrderRow })

        if (initialType !== 'orderRow') this.props.onSubmit({ productionOrder, productionOrderRow })
        else this.props.onSubmit({ productionOrderRow })
      } else {
        throw new Error('Production order row not found')
      }
    } catch (error) {
      showToastError(__(T.error.couldn_find_production_order_row))
    }
  }

  getOrderSelectionMode = () => {
    const { operation } = this.props
    return operation.options && operation.options.productionOrderRowSelectionMode
      ? operation.options.productionOrderRowSelectionMode
      : 'byOrderAndRowCode'
  }

  render() {
    const initialType = getInitialType(this.props.operation)
    const { data } = this.state
    const { product } = this.props
    // Initial type is product
    if (initialType === 'product' || initialType === 'wam') {
      if (product) return this.productInfoBox()
      return this.initialTypeProduct()
    }

    // Initial type is production order
    if (initialType === 'order') {
      if (!data || !data.productionOrder) return this.formSchemaProductionOrder()
      else if (data && data.productionOrder) return this.formSchemaProductionOrderRow()
    }

    // Initial type is order row
    if (initialType === 'orderRow') return this.formSchemaProductionOrderRow()
  }
}
